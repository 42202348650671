import React from 'react'
import Layout from '../components/layouts/Layout'

function PrivacyPolicy() {
    
    return (
        <Layout>
            <div className="container col-12 pt-5 px-5 " style={{marginTop:'100px'}} data-aos="slide-up">
            <div className="row flex-lg-row-reverse align-items-center g-5 pt-0">
                <div className="col-lg-12 text-start">
                    <h3 className="fw-bold text-primary  lh-1 mb-5 text-center">Privacy Notice</h3>
                    <h4>INTRODUCTION</h4>
                    <p className="lead">Welcome  to  the  Big  Innovation  Centre  Limited's  privacy  notice.  We  respect  your  privacy  and  are committed to protecting your personal data</p>
                    <ol>
                        <li><strong>Important information and who we are</strong>
                            <ol className="pt-3">
                                <li>Purpose of this privacy notice
                                    <p className="pt-3">This privacy notice aims to give you information on how Big Innovation Centre Limited collects and  processes  your  personal  data,  including  any  data  you  may  provide  through  our  website when you request services from us.
                                    </p><p className="">It is important that you read this privacy notice together with any other privacy notice we may provide on specific occasions when we are processing personal data about you so that you are
                                        fully aware of how and why we are using your data. This privacy notice supplements the other notices and is not intended to override them. </p>
                                    <p>This privacy notice was prepared in accordance with the requirements of the EU General Data Protection Regulation.</p>
                                </li>
                                <li>Controller
                                    <p className="pt-3">Big Innovation Centre Limited is the controller of your personal data ("we", "us" or "our" in this privacy notice)</p>
                                    <p>We  have  appointed  a  data  privacy  manager  who  is  responsible  for  overseeing  questions  in relation to this privacy notice. If you have any questions about this privacy notice, including any
                                        requests to exercise your legal rights, please contact the data privacy manager using the details set out below</p>
                                </li>
                                <li>Contact Details
                                    <p className="pt-3">Our full contact deails are:</p>
                                    <table className="table">
                                        <tbody><tr>
                                            <td>Full name of legal entity:</td>
                                            <td>Big Innovation Centre Limited </td>
                                        </tr>
                                            <tr>
                                                <td>Email address:</td>
                                                <td>accounts@biginnovationcentre.com</td>
                                            </tr>
                                            <tr>
                                                <td>Postal address:</td>
                                                <td>C/O Rpgcc, 40 Gracechurch Street, London, England, EC3V 0BT</td>
                                            </tr>
                                            <tr>
                                                <td>Telephone Number:</td>
                                                <td>02037134036 </td>
                                            </tr>
                                        </tbody></table>
                                    <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO),  the  UK  supervisory  authority  for  data  protection  issues  (www.ico.org.uk).  We  would,
                                        however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
                                </li>
                                <li>Changes to the privacy notice and your duty to inform us of changes
                                    <p className="pt-3">This version was last updated in July 2018.  </p>
                                    <p>It is important that the personal data we hold about you is accurate. Please keep us informed if your personal data changes during your relationship with us.</p>
                                </li>
                            </ol>
                        </li>
                        <li><strong>The data we collect about you</strong>
                            <ol>
                                <li className="py-3">Personal  data  means  any  information  about  an  individual  from  which  that  person  can  be identified. It does not include anonymous data. </li>
                                <li>We may collect, use, store and transfer different kinds of personal data about you as follows:
                                    <ol>
                                        <li className="pt-3"><b>Identity Data</b> includes first name, last name and title.  </li>
                                        <li><b>Contact Data</b> includes email address and telephone number.</li>
                                        <li><b>Financial Data</b> includes bank account and payment card details. </li>
                                        <li><b>Transaction Data</b> includes details about payments to and from you and other details of services you have purchased from us.</li>
                                        <li><b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type
                                            and  version,  time  zone  setting  and  location,  browser  plug-in  types  and  versions, operating  system  and  platform  and  other  technology  on  the  devices  you  use  to
                                            access our website. </li>
                                        <li><b>Profile  Data</b>  includes  your  username  and  password,  your  feedback  and  survey responses.</li>
                                        <li><b>Usage Data</b> includes information about how you use our website and services. </li>
                                        <li><b>Marketing  and  Communications  Data</b>  includes  your  preferences  in  receiving marketing from us.</li>
                                    </ol>
                                    <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered
                                        personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific
                                        website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly  or indirectly  identify you, we treat the combined data as personal data
                                        which will be used in accordance with this privacy notice.</p>
                                </li>
                                <li>If you fail to provide personal data
                                    <p className="pt-3">Where we need to collect personal data by law, or under the terms of a contract we have with you  and  you  fail  to  provide  that  data  when  requested,  we  may  not  be  able  to  perform  the
                                        contract we have or are trying to enter into with you.</p>
                                </li>
                            </ol>
                        </li>
                        <li><b>How is your personal data collected?</b>
                            <p className="pt-3">We use different methods to collect data from and about you including through: </p>
                            <ol>
                                <li><b>Direct interactions</b> - You may give us your Identity and Contact Data by corresponding with us. This includes personal data you provide when you:
                                    <ol className="pt-3">
                                        <li>request our services; </li>
                                        <li>create an account on our website; </li>
                                        <li>download publications;  </li>
                                        <li>subscribe to member groups;  </li>
                                        <li>request marketing to be sent to you; </li>
                                        <li>enter a competition, promotion or survey; or </li>
                                        <li>give us some feedback.  </li>
                                    </ol>

                                </li>
                            </ol>
                        </li>
                        <li><b>How we use your personal data</b>
                            <ol className="pt-3">
                                <li>We will only use your personal data when the law allows us to. Most commonly, we will use
                                    your personal data in the following circumstances:
                                    <ol className="pt-3">
                                        <li>Where we need to perform the contract we are about to enter into or have entered into with you. </li>
                                        <li>Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests.</li>
                                        <li>Where we need to comply with a legal or regulatory obligation.</li>
                                        <li>Where you have consented to receiving marketing communications from us.</li>
                                    </ol>
                                </li>
                                <li>Purposes for which we will use your personal data
                                    <p className="pt-3">We  have  set  out  below,  in  a  table  format,  a  description  of  all  the  ways  we  plan  to  use  your personal data, and which of the legal bases we rely on to do so.</p>
                                    <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details
                                        about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below. </p>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Purpose/Activity </th>
                                                <th scope="col">Type of data </th>
                                                <th scope="col">Lawful  basis  for  processing including  basis  of  legitimate interest </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="row">To  set  you  up  as  a  new client</td>
                                                <td><ol className="alpha">
                                                    <li>Identity </li>
                                                    <li>Contact</li>
                                                </ol>
                                                </td>
                                                <td>Performance  of  a  contract  with you</td>
                                            </tr>
                                            <tr>
                                                <td scope="row">To  process  any  payments from you</td>
                                                <td>
                                                    <ol className="alpha">
                                                        <li>Identity</li>
                                                        <li>Contact </li>
                                                        <li>Financial </li>
                                                        <li>Transaction</li>
                                                    </ol>
                                                </td>
                                                <td>
                                                    <ol className="alpha">
                                                        <li>Necessary for our legitimate interests (to recover debts due to us)</li>
                                                    </ol></td>
                                            </tr>
                                            <tr>
                                                <td scope="row">
                                                    To manage our relationship with you which will include: <br/>
                                                        <ol className="alpha pt-3">
                                                            <li>Notifying  you  about changes to our terms  or  privacy policy </li>
                                                            <li>Asking you to leave a  review  or  take  a survey</li>
                                                        </ol>
                                                </td>
                                                <td>
                                                    <ol className="alpha">
                                                        <li>Identity</li>
                                                        <li>Contact</li>
                                                        <li>Profile </li>
                                                        <li>Marketing and Communications</li>
                                                    </ol>
                                                </td>
                                                <td>
                                                    <ol className="alpha">
                                                        <li>Performance of a contract with you</li>
                                                        <li>Necessary to comply with a legal obligation</li>
                                                        <li>Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</li>
                                                        <ol>
                                                        </ol></ol></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To administer and protect our business and website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and ho+6sting of data)
                                                </td>
                                                <td>
                                                    <ol className="alpha">
                                                        <li>Identity</li>
                                                        <li>Contact</li>
                                                        <li>Technical</li>
                                                    </ol>
                                                </td>
                                                <td>
                                                    <ol className="alpha">
                                                        <li>Necessary for our legitimate interests (for running our business,
                                                            provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation
                                                            or group restructuring exercise) </li>
                                                        <li>Necessary to comply with a legal obligation</li>
                                                    </ol>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>To  use  data  analytics  to improve our website, products/services, marketing, customer relationships and experiences</td>
                                                <td>
                                                    <ol className="alpha">
                                                        <li>Technical</li>
                                                        <li>Usage</li>

                                                    </ol>
                                                </td>
                                                <td>
                                                    Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To  make  suggestions  and recommendations  to  you about goods or services that may be of interest to you                                        </td>
                                                <td>
                                                    <ol className="alpha">
                                                        <li>Identity</li>
                                                        <li>Contact</li>
                                                        <li>Technical </li>
                                                        <li>Usage  </li>
                                                        <li>Profile  </li>
                                                    </ol>
                                                </td>
                                                <td>
                                                    Necessary for our legitimate interests (to develop our products/services and grow our business)
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>Promotional offers from us
                                    <p className="pt-3">We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what
                                        we think you may want or need, or what may be of interest to you. This is how we decide which
                                        products, services and offers may be relevant for you (we call this marketing). </p>
                                    <p>You will receive marketing communications from us if you have requested information from us
                                        or  if  you  provided  us  with  your  details  when  you  entered  a  competition  or  registered  for  a
                                        promotion and, in each case, you have not opted out of receiving that marketing</p>
                                </li>
                                <li>Opting out
                                    <p className="pt-3">You can ask us to stop sending you marketing messages at any time by contacting us.  </p>
                                </li>
                                <li>Change of purpose
                                    <p className="pt-3">We  will  only  use  your  personal  data  for  the  purposes  for  which  we  collected  it,  unless  we
                                        reasonably consider that we need to use it for another reason and that reason is compatible
                                        with the original purpose. If you wish to get an explanation as to how the processing for the new
                                        purpose is compatible with the original purpose, please contact us.</p>
                                    <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will
                                        explain the legal basis which allows us to do so</p>
                                    <p>Please  note that we  may  process your personal data without your knowledge or  consent, in
                                        compliance with the above rules, where this is required or permitted by law</p>
                                </li>

                            </ol>

                        </li>
                        <li><b>Disclosures of your personal data</b>
                            <ol className="pt-3">
                                <li>We  will  only  disclose  your  personal  data  to  a  third  party  where  it  is  necessary  to  do  so,  for
                                    example, if you request to attend an event or conference and we need to inform the organiser
                                    that you will be attending it. </li>
                            </ol>

                        </li>
                        <li><b>International transfers</b>
                            <ol className="pt-3">
                                <li>We only transfer your personal data outside the EEA where the transfer is necessary for the
                                    performance of a contract between you and us (for example, if you request to attend a meeting
                                    overseas, we will need to share your details with the meeting organiser).   </li>
                            </ol>
                        </li>
                        <li><b>Data security</b>
                            <ol className="pt-3">
                                <li>We have put in place appropriate security measures to prevent your personal data from being
                                    accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition,
                                    we limit access to your personal data to those employees, agents, contractors and other third
                                    parties who have a business need to know. They will only process your personal data on our
                                    instructions and they are subject to a duty of confidentiality. </li>
                                <li>We  have  put  in  place  procedures  to  deal  with  any  suspected  personal  data  breach  and  will
                                    notify you and any applicable regulator of a breach where we are legally required to do so.</li>
                            </ol>

                        </li>
                        <li><b>Data retention</b>
                            <ol className="pt-3">
                                <li>How long will you use my personal data for?
                                    <p className="pt-3">We  will  only  retain  your  personal  data  for  as  long  as  necessary  to  fulfil  the  purposes  we
                                        collected  it  for,  including  for  the  purposes  of  satisfying  any  legal,  accounting,  or  reporting
                                        requirements</p>
                                    <p>To  determine  the  appropriate  retention  period  for  personal  data,  we  consider  the  amount,
                                        nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or
                                        disclosure of your personal data, the purposes for which we process your personal data and
                                        whether  we  can  achieve  those  purposes  through  other  means,  and  the  applicable  legal
                                        requirements. If you have any questions about how long we keep your personal data, please
                                        contact us.</p></li>
                            </ol>
                        </li>
                        <li><b>Your legal rights</b>
                            <ol className="pt-3">
                                <li>Under  certain  circumstances,  you  have  rights  under  data  protection  laws  in  relation  to  your
                                    personal data. You have a right to:
                                    <ol className="pt-3">
                                        <li><b>Request access</b> to your personal data (commonly known as a ‘data subject access request’). This enables you to receive a copy of the personal data we hold about
                                            you and to check that we are lawfully processing it.</li>
                                        <li><b>Request correction</b> of the personal data that we hold about you.  </li>
                                        <li><b>Request  erasure</b>  of  your  personal  data.  This  enables  you  to  ask  us  to  delete  or
                                            remove personal data where there is no good reason for us continuing to process it.
                                            You also have the right to ask us to delete or remove your personal data where you
                                            have successfully exercised your right to object to processing (see below), where we
                                            may have processed your information unlawfully or where we are required to erase
                                            your personal data to comply with local law. Note, however, that we may not always
                                            be able to comply with your request of erasure for specific legal reasons which will
                                            be notified to you, if applicable, at the time of your request. </li>
                                        <li><b>Object to processing</b> of your personal data where we are relying on a legitimate
                                            interest and there is something about your particular situation which makes you want to object  to processing  on  this  ground  as  you  feel  it  impacts  on  your  fundamental
                                            rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate
                                            that  we  have  compelling  legitimate  grounds  to  process  your  information  which
                                            override your rights and freedoms.</li>
                                        <li><b>Request restriction</b> of processing of your personal data. This enables you to ask
                                            us to suspend the processing of your personal data in the following scenarios: (a) if
                                            you want us to establish the data's accuracy; (b) where our use of the data is unlawful
                                            but you do not want us to erase it; (c) where you need us to hold the data even if we
                                            no longer require it as you need it to establish, exercise or defend legal claims; or (d)
                                            you have objected to our use of your data but we need to verify whether we have
                                            overriding legitimate grounds to use it. </li>
                                        <li><b>Request the transfer</b> of your personal data to you or to a third party. We will provide
                                            to  you,  or  a  third  party  you  have  chosen,  your  personal  data  in  a  structured,
                                            commonly  used,  machine-readable  format.  Note  that  this  right  only  applies  to
                                            automated information which you initially provided consent for us to use or where we
                                            used the information to perform a contract with you. </li>
                                        <li><b>Withdraw  consent </b> at  any  time  where  we  are  relying  on  consent  to  process  your
                                            personal data.  </li>
                                    </ol>
                                </li>
                                <li>No fee usually required
                                    <p className="pt-3">You  will  not  have  to  pay  a  fee  to  exercise  your  other  rights.  However,  we  may  charge  a
                                        reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we
                                        may refuse to comply with your request in these circumstances.</p>
                                </li>
                                <li>What we may need from you
                                    <p className="pt-3">We  may  need  to  request  specific  information  from  you  to  help  us  confirm  your  identity  and
                                        ensure your right to exercise your  rights. This is  a security measure to ensure that  personal
                                        data is not disclosed to any person who has no right to receive it. We may also contact you to
                                        ask you for further information in relation to your request to speed up our response.</p>
                                </li>
                                <li>Time limit to respond
                                    <p className="pt-3">We try to respond to all legitimate requests within one month. Occasionally it may take us longer
                                        than a month if your request is particularly complex or you have made a number of requests.
                                        In this case, we will notify you and keep you updated.  </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
      </Layout >
     )
}
export default PrivacyPolicy
