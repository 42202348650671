import React, { useState,useEffect } from 'react'
import {useParams, useNavigate, useLocation } from 'react-router-dom'
import ApartmentIcon from '@mui/icons-material/Apartment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Group from '@mui/icons-material/Group'
import http from '../http';
import Header101 from '../components/Header101';
import Footer101 from '../components/Footer101';
import { Row, Col, Container } from 'react-bootstrap';
import Swal from 'sweetalert2'
import axios from 'axios';

function ResetPwd() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');

  const [otp, setOtp] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [otpField, setOtpField] = useState(false);

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  // Get the token from the URL
  const { token } = useParams();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  useEffect(() => {
    if(splitURL[1]==='resetpassword'){
      if(splitURL[2]){
        setShowForm(false);
      }

    }
  }, [splitURL]);

  const otpHandler = (event) => {
    setOtp(event.target.value)
  }
  
  const emailHandler = (event) => {
    setEmail(event.target.value)
  }
  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }
  const cpasswordHandler = (event) => {
    setCPassword(event.target.value)
  }
  function resetRequest() {
    http().post('password/reset/otp', { email: email})
        .then((res) => {
          Swal.fire({
            title: res.data.message,
            icon: "info",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });	
            // navigate('/');
            setOtpField(true);
        })
        .catch((error) => {
            console.error('Error:', error);
            Swal.fire({
              title: error.response.data.message,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });	
        });
  }

  function verifyResetRequest() {
    http().post('password/reset/verifyotp', { email:email, otp: otp})
        .then((res) => {
          Swal.fire({
            title: res.data.message,
            icon: "info",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });	
            // navigate('/');
            setShowForm(false);
        })
        .catch((error) => {
            console.error('Error:', error);
            Swal.fire({
              title: error.response.data.message,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });	
            setShowForm(true);
        });
  }

  const submitHandler = (event) => {
    event.preventDefault()
    resetRequest()
  }
  const submitHandler1 = (event) => {
    event.preventDefault()
    verifyResetRequest()
  }
  
  const submitHandler2 = async (event) => {
    event.preventDefault();

    // Check if passwords match
    if (password !== cpassword) {
      setError('Passwords do not match');
      return;
    }

    // Create form data
    const formData = {
      email,
      password,
      password_confirmation: cpassword,
      token,
    };

    try {
      // Make the API call
      const response = await http().post('password/reset/resetPasswordmobile', formData);
      Swal.fire({
        title: "Password has been reset successfully.",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });	
      //alert('Reset password request sent to your email.');
      navigate('/');
      setError('');
    } catch (err) {
      // Handle error
      Swal.fire({
        title: err.response.data.message,
        icon: "error",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
    }
  };
  
  return (
    <div className="wrapper">
    <Header101/>
    <Container className="content-container content d-flex justify-content-center bg-register " >
    <div className="container col-xxl-8 px-0 pt-5">
      <div className="row  align-items-center g-5 pt-5">
    <div className="container col-lg-6 p-5 " data-aos="slide-right">
          <h3 className="fw-bold text-light lh-1 mb-3">Welcome to Pavilion 3.0 </h3>
          <p className="lead text-light">Share your professional interests, contribute and be connected with your community.</p>
          {showForm ? (
            <form className="py-1 p-md-0 rounded-3 bg-transparent " onSubmit={otpField ? submitHandler1 : submitHandler }>
            <div className="form-floating mb-1">
              <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={emailHandler}/>
              <label for="floatingInput">Email Address</label>
            </div>

           {otpField == false && <div className="checkbox mb-3">
              <button className="btn btn-lg btn-secondary w-100" type="submit">RESET PASSWORD</button>
            </div>
            }

{otpField && <div>
              <div className="form-floating mb-1">
                <input type="text" className="form-control" id="floatingInput" placeholder="6 digit code" onChange={otpHandler}/>
                <label for="floatingInput">Enter OTP Code</label>
              </div>
              <div className="checkbox mb-3">
                <button className="btn btn-lg btn-secondary w-100" type="submit">VERIFY OTP</button>
              </div> 
              </div>
              }

          </form>
          ) : (
<form className="py-1 p-md-0 rounded-3 bg-transparent" onSubmit={submitHandler2}>
      <div className="form-floating mb-1">
        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={emailHandler} required />
        <label htmlFor="floatingInput">Email Address</label>
      </div>
      <div className="form-floating mb-1">
        <input type="password" className="form-control" id="password" placeholder="Password" onChange={passwordHandler} required />
        <label htmlFor="password">Password</label>
      </div>
      <div className="form-floating mb-1">
        <input type="password" className="form-control" id="cpassword" placeholder="Confirm Password" onChange={cpasswordHandler} required />
        <label htmlFor="cpassword">Confirm Password</label>
      </div>
      <div className="checkbox mb-3">
        <button className="btn btn-lg btn-secondary w-100" type="submit">RESET PASSWORD</button>
      </div>

      {/* Display error or success message */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </form>
            ) }
          
          <div className="container text-light">
            <p>Already have an account? <a href="/" className='btn btn-link btn-outline-light btn-sm'>Sign In</a></p>
          </div>
        </div>
        <div className="col-lg-6 px-5 " data-aos="slide-left">
          <div className="col-12 d-flex flex-column justify-content-center mx-5">
            <h1 className="m-4 text-light "><ApartmentIcon/> Companies</h1>
            <h1 className="m-4 text-light "><MenuBookIcon/> Academia</h1>
            <h1 className="m-4 text-light "><AssuredWorkloadIcon/> Government</h1>
            <h1 className="m-4 text-light "><Group/> Citizens</h1>
           </div>
        </div>
        </div></div>
      </Container>
      <Footer101/>
    </div>
  //   <div>

  //  <header id="myHeader" className="mb-0 mt-5 mt-sm-5 p-5 bg-register">
  //   <div className="container col-xxl-8 px-0 pt-5 ">
  //     <div className="row  align-items-center g-5 pt-5">
        
  //     </div>
  //   </div>
  //  </header>
  //   </div>
    
    
  )

}

export default ResetPwd